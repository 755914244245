
import { defineComponent } from "vue";
import Row from "@/components/base/common/Row.vue";
import CaseStatus from "@/views/case/CaseStatus.vue";
import { LoadPanel } from "@/core/composite/composite";
import CaseLink from "@/views/case/CaseLink.vue";
import CaseType from "@/views/case/CaseType.vue";

export default defineComponent({
  name: "CaseCard",
  components: {CaseType, CaseLink, CaseStatus, Row},
  props: {
    caze: {required: true, type: Object}
  },
  setup() {
    return {
      ...LoadPanel(),
    }
  }
})
