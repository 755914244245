
import {LoadPanel} from "@/core/composite/composite";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CaseLink",
  props: {
    cazeId: {type:String, required:true},
    cazeNumber : {type:String, required:true}
  },
  setup() {
    return {
      ...LoadPanel()
    }
  }
})
