
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "CaseStatus",
  props: {
    status: {required: true}
  },
  setup(props) {
    const data = ref({clazz: ''})

    const buildStatus = (status) => {
      if (status.code) {
        switch (status.code) {
          case 'Pending' :
          case 'PENDING':
            data.value = {clazz: 'primary'}
            break
          case 'ACTIVE':
          case 'Active':
            data.value = {clazz: 'success'}
            break
          case 'CLOSE':
          case 'Close':
          case 'CLOSED':
          case 'Closed':
            data.value = {clazz: 'info'}
            break
        }
      } else {
        switch (status) {
          case 'Pending' :
          case 'PENDING':
            data.value = {clazz: 'primary'}
            break
          case 'ACTIVE':
          case 'Active':
            data.value = {clazz: 'success'}
            break
          case 'CLOSE':
          case 'Close':
          case 'CLOSED':
          case 'Closed':
            data.value = {clazz: 'info'}
            break
        }
      }

    }
    watch(() => props.status, (cb) => {
      buildStatus(cb)
    })
    buildStatus(props.status)
    return {
      data
    }
  }
})
